import React from 'react';

export class CloseButton extends React.Component {
  render() {
    return (
      <div
        className={`close-button ${this.props.containerClass || ''}`}
        onClick={this.props.clickHandler()}
      >
        <svg viewBox="0 0 50 50">
          <path
            className={`close-button__x ${this.props.xClass || ''}`}
            d="M 2,2 L 48,48 M 48,2 L 2,48"
          />
        </svg>
      </div>
    );
  }
}
