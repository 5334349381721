import {
  types,
  onSnapshot,
  getEnv,
  flow,
  getRoot,
  applySnapshot
} from "mobx-state-tree";
import * as R from "ramda";

import { ListingModel } from "../models/listing";
import { MapListingPinModel } from "../models/map-listing-pin";
import { localCache, LISTING_STORE_KEY } from "../services/local-cache";

import {
  rangeStep,
  camelizeResponse,
  filterObjectArrayByAttr,
  getSubsetOfArray,
  getQueryStringValue
} from "../lib/utils";

export const PAGINATION_LIMIT = 12;
// This is the point at which we stop zooming into clusters and just output a multi-listing infowindow. In other words, if there are < 8 unique lat/lngs in a given cluster, output infowindow
export const UNIQUE_MAP_CLUSTER_MARKER_LIMIT = 8;
export const TABLET_WIDTH_BREAKPOINT_PX = 768;
export const PRICE_FILTER_VALUES = R.pipe(
  R.flatten,
  R.uniq
)([
  rangeStep(0, 25000, 500000),
  rangeStep(500000, 50000, 1000000),
  rangeStep(1000000, 100000, 2000000),
  rangeStep(2000000, 500000, 3000000),
  rangeStep(3000000, 1000000, 5000000),
  rangeStep(5000000, 2500000, 10000000)
]);

export const BED_FILTER_VALUES = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5+", value: 5 }
];
export const BATH_FILTER_VALUES = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5+", value: 5 }
];
export const PROPERTY_TYPE_FILTER_VALUES = [
  { label: "Apartment/Condo", value: "Apartment/Condo" },
  { label: "Townhouse", value: "Townhouse" },
  { label: "House", value: "House" },
  { label: "Duplex", value: "Duplex"}
];

export const BC_SEARCH_BOUNDS = [
  { lat: 48.086769, lng: -130.074537 },
  { lat: 59.999998, lng: -120.000074 }
];

// Smaller Vancouver bounds set as default, because fitBounds zooms out one level for some reason
const DEFAULT_BOUNDS = [
  49.22398641870703,
  -123.16251747811208,
  49.300164239830124,
  -122.97616949805985
];

const PERSISTED_KEYS = [
  "pageNumber",
  "apiPageNumber",
  "searchTerm",
  "bathsSelectionArray",
  "bedroomsSelectionArray",
  "boundsMaxLatitude",
  "boundsMaxLongitude",
  "boundsMinLatitude",
  "boundsMinLongitude",
  "priceMax",
  "priceMin",
  "propertyTypesSelectionArray",
  "orderByDirection"
];

export const ListingStoreModel = types
  .model("ListingStoreModel")
  .props({
    searchTerm: types.optional(types.string, ""),
    apiPageNumber: types.optional(types.number, 1),
    bathsSelectionArray: types.optional(types.array(types.number), []),
    bedroomsSelectionArray: types.optional(types.array(types.number), []),
    boundsMinLatitude: types.maybeNull(types.number),
    boundsMinLongitude: types.maybeNull(types.number),
    boundsMaxLatitude: types.maybeNull(types.number),
    boundsMaxLongitude: types.maybeNull(types.number),
    contactModalContext: types.optional(types.string, "schedule"),
    contactSubmitMessage: types.maybeNull(types.string),
    currentUserLoggedIn: types.optional(types.boolean, false),
    currentListingData: types.maybeNull(ListingModel),
    currentListingId: types.maybeNull(types.number),
    favouriteListings: types.optional(types.array(ListingModel), []),
    isLoading: types.optional(types.boolean, false),
    listings: types.optional(types.array(ListingModel), []),
    mapListingPins: types.optional(types.array(MapListingPinModel), []),
    menuIsOpen: types.optional(types.boolean, false),
    mobileMapIsVisible: types.optional(types.boolean, true),
    needsToFetchListings: types.optional(types.boolean, false),
    omittedSearchResultsMessageDismissed: types.optional(types.boolean, false),
    orderByDirection: types.optional(types.string, "asc"),
    pageNumber: types.optional(types.number, 0),
    priceMax: types.maybeNull(types.number),
    priceMin: types.maybeNull(types.number),
    propertyTypesSelectionArray: types.optional(types.array(types.string), []),
    selectedMapListingData: types.maybeNull(ListingModel),
    selectedMapListingId: types.maybeNull(types.number),
    showFavourites: types.optional(types.boolean, false),
    showFilters: types.optional(types.boolean, false),
    showGridView: types.optional(types.boolean, false),
    contactModalListingId: types.maybeNull(types.number),
    showMobileMapInfoWindow: types.optional(types.boolean, false),
    totalListings: types.optional(types.number, 0),
    clustererShouldLoad: types.optional(types.boolean, false),
    detachedInfoWindowLat: types.maybeNull(types.number),
    detachedInfoWindowLng: types.maybeNull(types.number),
    detachedInfoWindowListingData: types.optional(types.array(ListingModel), [])
  })
  .views(self => ({
    get showDetachedInfoWindow() {
      if (
        R.isNil(self.detachedInfoWindowLat) ||
        R.isNil(self.detachedInfoWindowLng)
      ) {
        return false;
      } else {
        return !(
          R.isEmpty(self.detachedInfoWindowLat) ||
          R.isEmpty(self.detachedInfoWindowLng)
        );
      }
    },
    get mapIsReady() {
      return (
        self.boundsMinLatitude &&
        self.boundsMinLongitude &&
        self.boundsMaxLatitude &&
        self.boundsMaxLongitude
      );
    },
    get showListingContactModal() {
      return !R.isNil(self.contactModalListingId);
    },
    get showOmittedSearchResultsMessage() {
      return (
        !self.showGridView &&
        self.totalListings > 1000 &&
        !self.omittedSearchResultsMessageDismissed
      );
    },
    get contactModalListingData() {
      return (
        R.find(R.propEq("id", self.contactModalListingId))(
          self.favouriteListings
        ) ||
        R.find(R.propEq("id", self.contactModalListingId))(self.listings) ||
        self.currentListingData ||
        null
      );
    },
    get bathOptions() {
      return BATH_FILTER_VALUES;
    },
    get bedOptions() {
      return BED_FILTER_VALUES;
    },
    get currentListingsCount() {
      let start =
        self.totalListings == 0
          ? 0
          : (self.apiPageNumber - 1) * PAGINATION_LIMIT + 1;

      let end = start + PAGINATION_LIMIT - 1;
      if (end > self.totalListings) {
        end = self.totalListings;
      }

      return `${start} - ${end}`;
    },
    get maxPriceOptions() {
      return PRICE_FILTER_VALUES.filter(value => value > self.priceMin);
    },
    get minPriceOptions() {
      return R.isNil(self.priceMax)
        ? PRICE_FILTER_VALUES
        : PRICE_FILTER_VALUES.filter(value => value < self.priceMax);
    },
    get numFiltersOn() {
      let numFilters = 0;

      self.bathsSelectionArray.length > 0 ? (numFilters += 1) : "";
      self.bedroomsSelectionArray.length > 0 ? (numFilters += 1) : "";
      self.propertyTypesSelectionArray.length > 0 ? (numFilters += 1) : "";
      self.priceMax > 0 ? (numFilters += 1) : "";
      self.priceMin > 0 ? (numFilters += 1) : "";

      return numFilters;
    },
    get pageCount() {
      return Math.ceil(self.totalListings / PAGINATION_LIMIT);
    },
    get propertyTypeOptions() {
      return PROPERTY_TYPE_FILTER_VALUES;
    }
  }))
  .actions(self => ({
    startup(cachedStore) {
      // do we have a local cached version of the store? lets restore it
      if (!R.isNil(cachedStore)) {
        applySnapshot(self, R.pick(PERSISTED_KEYS, cachedStore));
      } else {
        self.updateBounds(...DEFAULT_BOUNDS);
      }
    },
    afterCreate() {
      onSnapshot(self, snapshot => {
        localCache.save(LISTING_STORE_KEY, snapshot);
      });

      localCache.get(LISTING_STORE_KEY).then(store => {
        self.startup(store);
      });

      self.getFavouriteListings();
    },
    setDetachedInfoWindowPosition(lat, lng) {
      self.detachedInfoWindowLat = lat;
      self.detachedInfoWindowLng = lng;
    },
    setSearchTerm(term) {
      self.searchTerm = term;
    },
    clearFilters() {
      self.bathsSelectionArray = [];
      self.bedroomsSelectionArray = [];
      self.priceMax = null;
      self.priceMin = null;
      self.propertyTypesSelectionArray = [];
      self.orderByDirection = "asc";
      self.searchTerm = "";
      // applySnapshot(self, {});
      self.getListings();
    },
    clearSelectedMapListingData() {
      self.selectedMapListingData = null;
    },
    clearDetachedInfoWindowListingData() {
      self.detachedInfoWindowListingData = [];
    },
    dismissOmittedSearchResultsMessage() {
      self.omittedSearchResultsMessageDismissed = true;
    },
    getFavouriteListings: flow(function*() {
      const env = getEnv(self);
      const response = yield env.api.getFavouriteListings();
      if (response.ok) {
        self.favouriteListings = camelizeResponse(
          response.data["favourite_listings"]
        );
        self.currentUserLoggedIn = response.data.meta["current_user_logged_in"];
      } else {
        // error message
      }
    }),
    // getListingData: flow(function*(listingId, listingDataProp) {
    //   if (R.isNil(listingId)) {
    //     return;
    //   } else {
    //     self[listingDataProp] = null;
    //     const env = getEnv(self);
    //     const response = yield env.api.getListingData(listingId);
    //     if (response.ok) {
    //       self[listingDataProp] = camelizeResponse(response.data["listing"]);
    //       self.currentUserLoggedIn =
    //         response.data.meta["current_user_logged_in"];
    //     } else {
    //       // error message
    //     }
    //   }
    // }),
    getListingDataById: (flow(function*(listingId) {
      if (R.isNil(listingId)) {
        return;
      } else {
        self.selectedMapListingData = null
        const env = getEnv(self);
        const response = yield env.api.getListingData(listingId);
        if (response.ok) {
          self.selectedMapListingData = camelizeResponse(response.data["listing"]);
          self.currentUserLoggedIn =
            response.data.meta["current_user_logged_in"];
        }
      }
    })),
    getDataForMultipleListings: flow(function*(
      listingIds,
      listingStoreProperty
    ) {
      if (R.isNil(listingIds) || R.isEmpty(listingIds)) {
        return;
      } else {
        self[listingStoreProperty] = [];
        const env = getEnv(self);
        const response = yield env.api.getMultipleListingsData(listingIds);
        if (response.ok) {
          self[listingStoreProperty] = camelizeResponse(
            response.data["listings"]
          );

          self.currentUserLoggedIn =
            response.data.meta["current_user_logged_in"];
        } else {
          // render error
        }
      }
    }),
    getCurrentListingDataBySlug: flow(function*(slug) {
      if (R.isNil(slug)) {
        return;
      } else {
        self.currentListingData = null;
        const env = getEnv(self);
        const response = yield env.api.getListingData(slug);
        if (response.ok) {
          self.currentListingData = camelizeResponse(response.data["listing"]);
          self.currentListingId = response.data["listing"]["id"];
          self.currentUserLoggedIn =
            response.data.meta["current_user_logged_in"];
        } else {
          // error message
        }
      }
    }),
    getListings: flow(function*() {
      const env = getEnv(self);
      self.isLoading = true;
      self.clustererShouldLoad = false;
      const response = yield env.api.getListings({
        per: PAGINATION_LIMIT,
        baths_selection_array: self.bathsSelectionArray,
        bedrooms_selection_array: self.bedroomsSelectionArray,
        property_types_selection_array: self.propertyTypesSelectionArray,
        price_max: self.priceMax,
        price_min: self.priceMin,
        page: self.apiPageNumber,
        order_by_direction: self.orderByDirection,
        search_bounds: [
          self.boundsMinLatitude,
          self.boundsMinLongitude,
          self.boundsMaxLatitude,
          self.boundsMaxLongitude
        ]
      });
      if (response.ok) {
        self.listings = camelizeResponse(response.data["sidebar_listings"]);
        self.mapListingPins = camelizeResponse(
          response.data["lat_lng_listings"]
        );
        self.totalListings = response.data.meta["total_count"];
        self.currentUserLoggedIn = response.data.meta["current_user_logged_in"];
        self.omittedSearchResultsMessageDismissed = false;
        self.isLoading = false;
        self.needsToFetchListings = false;
        self.clustererShouldLoad = true;
      } else {
        // error message
      }
    }),
    openMobileMapInfoWindow() {
      // We need open and close instead of just a single toggle because a user may tap on several map pins, and that shouldn't open and close the mobile info window
      self.showMobileMapInfoWindow = true;
    },
    closeMobileMapInfoWindow() {
      self.showMobileMapInfoWindow = false;
    },
    replaceProp(field, value) {
      self[field] = value;
      self.getListings();
    },
    setContactModalListingId(listingId) {
      self.contactModalListingId = listingId;
    },
    setContactModalContext(context) {
      self.contactModalContext = context;
    },
    setContactSubmitMessage(message = null) {
      self.contactSubmitMessage = message;
    },
    setInitialBoundsFromUrl: flow(function*() {
      const params = ["minLat", "minLng", "maxLat", "maxLng"].map(param =>
        Number(getQueryStringValue(param))
      );
      // if there are any params lets use them, otherwise use defaults
      if (R.any(param => param == 0, params)) {
        self.updateBounds(...DEFAULT_BOUNDS);
      } else {
        self.updateBounds(...params);
        window.history.replaceState({}, document.title, "/search");
      }
    }),
    setMenuIsOpen() {
      if (self.showFavourites || self.showFilters) {
        self.menuIsOpen = true;
      } else {
        self.menuIsOpen = false;
      }
    },
    setNeedsToFetchListings(bool) {
      self.needsToFetchListings = bool;
    },
    setProp(field, value) {
      self[field] = value != 0 ? value : null;
      self.getListings();
    },
    toggleGridView() {
      self.showGridView = !self.showGridView;
    },
    toggleListingContactModal(listingId = null) {
      self.setContactModalListingId(listingId);
      self.setContactSubmitMessage();
      // Mimics .js-trigger-modal click event in solid-modals.js
      document.body.classList.toggle("js-show-modal");
    },
    toggleMobileMapView() {
      self.mobileMapIsVisible = !self.mobileMapIsVisible;
    },
    toggleShowFavourites() {
      self.showFavourites = !self.showFavourites;
      self.setMenuIsOpen();
    },
    toggleShowFilters() {
      self.showFilters = !self.showFilters;
      self.setMenuIsOpen();
    },
    updateBounds(minLat, minLng, maxLat, maxLng) {
      self.boundsMinLatitude = minLat;
      self.boundsMinLongitude = minLng;
      self.boundsMaxLatitude = maxLat;
      self.boundsMaxLongitude = maxLng;
    },
    updatePageNumber(pageNumber) {
      self.pageNumber = pageNumber;
      self.apiPageNumber = pageNumber + 1;
      self.getListings();
    },
    updateSelectedMapListingId(id) {
      self.selectedMapListingId = id;
    },
    setFavouriteMetaData() {
      self.listings.map(listing => {
        self.favouriteMetaData.push(listing.favouriteMetaData);
      });
    }
  }));
