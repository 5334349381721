import React from "react";
import * as R from "ramda";

export class AgentCard extends React.Component {
  renderPrec = () => {
    const { prec, propertyManagerLicensed, showAgentTitle } = this.props.agent;
    return (
      <p>
        {
         prec &&
        <sup className="agent-card__prec">
          Personal Real Estate Corporation
        </sup>
        }
        {(!prec || showAgentTitle)?  (<span>
          {prec && <br/>}
          <sup className="agent-card__prec">
            Agent
          </sup>
        </span>) : null}
        {propertyManagerLicensed && (<span>
          <br/>
          <sup className="agent-card__prec">
            Property Manager
          </sup>
        </span>)}
      </p>
    );
  };

  render() {
    const { agent } = this.props;
    return (
      <li className="agent-card col xs-col-6 sm-col-4 md-col-3 xs-mb3">
        <a href={agent.agentUrl} className="agent-card__image-link">
          <div className="ar-4-5 xs-mb1">
            <img
              src={agent.imageUrl}
              className="agent-card__image"
              alt={agent.fullName}
            />
          </div>
        </a>
        <div>
          <a href={agent.agentUrl} className="agent-card__name-link">
            {agent.fullName}
            {agent.pronoun && agent.pronoun !== "prefer_not_to_say" ?
                <span className={"agent-card__pronoun-small xs-ml05"}>({agent.pronoun})</span> : null}
          </a>
          {this.renderPrec()}
          <div className="border-width-3 xs-my1" />
          <span className="agent-card__office xs-mb05">
            {agent.officeLocation}
          </span>
          <div>
            <a
              href={`tel:${agent.phone}`}
              className="agent-card__contact-link xs-mb05"
            >
              {agent.phone}
            </a>
            <a
              href={`mailto:${agent.email}`}
              className="agent-card__contact-link"
            >
              {agent.email}
            </a>
          </div>
        </div>
      </li>
    );
  }
}
