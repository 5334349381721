import React from "react";

import { inject, observer } from "mobx-react";
import {
  GoogleMap,
  InfoWindow,
  MarkerClusterer,
  MarkerF as Marker
} from "@react-google-maps/api";
import { MobileMapInfoWindow } from "./mobile-map-info-window";
import { CompactListingCard } from "./compact-listing-card";
import { Styles } from "../../shared/map-styles";
import { getQueryStringValue } from "../../lib/utils";
import { OAKWYN_O_ICON_URL } from "../../services/constants";
import {
  viewportLat,
  viewportLng,
  BOUNDS_MIN,
  BOUNDS_MAX
} from "../../lib/utils";
import {
  UNIQUE_MAP_CLUSTER_MARKER_LIMIT,
  TABLET_WIDTH_BREAKPOINT_PX
} from "../../stores/listing-store";
import { RootStoreWrapper } from "../../root-store-wrapper";
import * as R from "ramda";

const MapViewComponent = inject("listingStore")(observer((props) => {

  const renderDesktopInfoWindow = listing => {
    const {
      selectedMapListingData,
    } = props.listingStore;
    if (selectedMapListingData && window.innerWidth > TABLET_WIDTH_BREAKPOINT_PX) {
      return (
        <InfoWindow 
          position={{ lat: selectedMapListingData.latitude, lng: selectedMapListingData.longitude }} 
          onCloseClick={resetInfoWindowListing}
        >
           <CompactListingCard
              key={listing.id}
              listing={selectedMapListingData}
            />
        </InfoWindow>
      );
    }
  };

  const renderDetachedInfoWindow = () => {
    const {
      detachedInfoWindowLat,
      detachedInfoWindowLng,
      detachedInfoWindowListingData
    } = props.listingStore;

    if (window.innerWidth > TABLET_WIDTH_BREAKPOINT_PX) {
      return (
        <InfoWindow
          position={{ lat: detachedInfoWindowLat, lng: detachedInfoWindowLng }}
        >
          <div>
            {detachedInfoWindowListingData.map(listing => {
              return <CompactListingCard key={listing.id} listing={listing} />;
            })}
          </div>
        </InfoWindow>
      );
    } else {
      return (<></>)
    }
  };

  const renderMarkers = (clusterer, mapListingPins, selectedMapListingId, selectedMapListingData) => {
    return mapListingPins.map(listing => {
      return (
        <Marker
          key={listing.id}
          position={{ lat: listing.latitude, lng: listing.longitude }}
          icon={OAKWYN_O_ICON_URL}
          onClick={e => onMarkerClick(e, listing)}
          title={`${listing.id}`}
          clusterer={clusterer}
        >
          {selectedMapListingId == listing.id &&
            selectedMapListingData &&
            renderDesktopInfoWindow(listing)}
        </Marker>
      );
    });
  };

  // Resets all info window related data for both single markers and cluster markers.
  // The single marker uses a regular info window, whereas the cluster marker uses our custom DetachedInfoWindow
  const resetInfoWindowListing = () => {
    const {
      updateSelectedMapListingId,
      clearSelectedMapListingData,
      setDetachedInfoWindowPosition,
      clearDetachedInfoWindowListingData
    } = props.listingStore;
    // For the single marker info window, clear the selected map listing id and data
    updateSelectedMapListingId(null);
    clearSelectedMapListingData();

    // For the cluster (multi-listing) info window:
    // 1. reset the position
    setDetachedInfoWindowPosition(null, null);
    // 2. clear the data
    clearDetachedInfoWindowListingData();
  }

  const onMarkerClick = (e, listing) => {
    const {
      updateSelectedMapListingId,
      getListingDataById,
      openMobileMapInfoWindow
    } = props.listingStore;

    resetInfoWindowListing();

    listing
      ? updateSelectedMapListingId(listing.id)
      : updateSelectedMapListingId(null);
    getListingDataById(listing.id);

    if (window.innerWidth <= TABLET_WIDTH_BREAKPOINT_PX) {
      // The mobile map info window is just a hidden component that is absolutely positioned over the search results
      openMobileMapInfoWindow();
    }
  };

  const onMarkerClustererClick = cluster => {
    const {
      setDetachedInfoWindowPosition,
      getDataForMultipleListings,
      openMobileMapInfoWindow
    } = props.listingStore;

    resetInfoWindowListing();

    const markers = cluster.getMarkers();
    const uniqueClickedMarkers = R.uniq(
      markers.map(cm => [cm.position.lat(), cm.position.lng()])
    );

    if (R.length(uniqueClickedMarkers) < UNIQUE_MAP_CLUSTER_MARKER_LIMIT) {
      const center = cluster.getCenter();
      setDetachedInfoWindowPosition(center.lat(), center.lng());

      const listingIds = markers.map(m => m.title);
      getDataForMultipleListings(listingIds, "detachedInfoWindowListingData");

      if (window.innerWidth <= TABLET_WIDTH_BREAKPOINT_PX) {
        // The mobile map info window is just a hidden component that is absolutely positioned over the search results
        openMobileMapInfoWindow();
      }
    }
  };

  const handleOnBoundsChanged = () => {
    if (props.map) {
      const bounds = props.map.getBounds();
      if (bounds) {
        const searchBounds = [
          viewportLat(bounds, BOUNDS_MIN),
          viewportLng(bounds, BOUNDS_MIN),
          viewportLat(bounds, BOUNDS_MAX),
          viewportLng(bounds, BOUNDS_MAX)
        ];
  
        const {
          needsToFetchListings,
          updateBounds,
          getListings
        } = props.listingStore;
        updateBounds(...searchBounds);
  
        if (needsToFetchListings) {
          getListings();
        }
      }
    }
  };

  const handleOnDragEnd = () => {
    const { getListings, setSearchTerm } = props.listingStore;
    getListings();
    setSearchTerm("");
  };

  const handleOnZoomChanged = () => {
    // This flag is to account for the fact that zoom and drag do not behave the same way. Can't call getListings here because the bounds change after the zoom, unlike the dragEnd where the bounds change after the dragEnd.
    const { setNeedsToFetchListings } = props.listingStore;
    setNeedsToFetchListings(true);
    // This is commented out because we want the searchTerm to persist in the search input, but zoom occurs when fitBounds is called
    // setSearchTerm("");
  };

  const {
    boundsCenterLatitude,
    boundsCenterLongitude,
    clustererShouldLoad,
    showDetachedInfoWindow,
    selectedMapListingData,
    mapListingPins, 
    selectedMapListingId
  } = props.listingStore;
  
  return props.isLoaded ? (<>
    <MobileMapInfoWindow key={"mobile-map-info-window"}/>
    <div className={props.containerClass} id={"maps-container"}>
      <GoogleMap
        key={"google-map"}
        mapContainerStyle={{
          height: "100%",
          width: "100%"
        }}
        onBoundsChanged={handleOnBoundsChanged}
        onZoomChanged={handleOnZoomChanged}
        onDragEnd={handleOnDragEnd}
        options={{ gestureHandling: "greedy", styles: Styles.mapStyles }}
        onLoad={props.onLoad}
        onUnmount={props.onUnmount}
      >
        {showDetachedInfoWindow && renderDetachedInfoWindow()}
        {clustererShouldLoad && (
          <MarkerClusterer
            onClick={onMarkerClustererClick}
            averageCenter // This does not affect the lat/lngs of the clustered markers, just the positioning of the cluster itself
            zoomOnClick={!showDetachedInfoWindow}
            enableRetinaIcons
            gridSize={60}
            styles={[
              {
                height: 30,
                width: 30,
                url: OAKWYN_O_ICON_URL,
                // anchorIcon: [15, 0], // This was used to offset the positioning of the icon. Removing it so that the detached infowindow is correctly aligned with the cluster
                textSize: 9
              }
            ]}
          >
            {(clusterer) => (
              <>
              {renderMarkers(clusterer, mapListingPins, selectedMapListingId, selectedMapListingData)}
              </>
            )}
          </MarkerClusterer>
        )}
      </GoogleMap>
    </div>
  </>) : (<></>)
}))

export const MapView = MapViewComponent;
