import React from "react";
import * as R from "ramda";
import { inject, observer } from "mobx-react";
import { FavouriteButton } from "./favourite-button";
import { ScheduleButton } from "./schedule-button";

@inject("listingStore")
@observer
export class ListingCard extends React.Component {
  render() {
    const {
      id,
      humanizedPrice,
      address,
      bedroomsTotal,
      bathsTotal,
      neighbourhood,
      city,
      areaTotal,
      shortPropertyType,
      mlNumber,
      coverImageUrl,
      favourited,
      slug
    } = this.props.listing;

    return (
      <div className="listing-card-col col xs-col-12 xs-mb4">
        <a href={`/properties/${slug}`} className="listing-card">
          <div className="listing-card-image-wrapper ar-3-2 xs-mb1">
            {coverImageUrl && (
              <img
                src={coverImageUrl}
                className="aspect-ratio-content aspect-ratio-image"
              />
            )}
          </div>
          <div className="listing-card__price listing-card__price--overlay">
            <span>{humanizedPrice}</span>
          </div>
          <div className="listing-card-details">
            <div className="listing-card__save-button-mobile-container md-hide">
              <FavouriteButton
                listing={this.props.listing}
                isMobileButton={true}
                hideButtonText={true}
              />
            </div>
            <div className="listing-card__address">{address}</div>
            <div className="listing-card__city">{neighbourhood ? `${neighbourhood}, ${city}` : `${city}`}</div>
            <div className="listing-card-attributes-wrapper xs-listing-card-attributes-wrapper--stacked">
              <span className="listing-card__attribute listing-card__beds xs-mr3">
                {bedroomsTotal}
              </span>
              <span className="listing-card__attribute listing-card__baths xs-mr3">
                {bathsTotal}
              </span>
              <span className="listing-card__attribute listing-card__sqft xs-mr-auto">
                {areaTotal}
              </span>
            </div>
            <div className="listing-card-attributes-wrapper">
              <span className="listing-card__attribute listing-card__type xs-mr1">
                {shortPropertyType}
              </span>
              <span className="listing-card__attribute listing-card__id xs-mx-auto">
                {mlNumber}
              </span>
              <FavouriteButton
                listing={this.props.listing}
                additionalClasses="xs-hide md-block"
              />
            </div>
          </div>
          <ScheduleButton
            listing={this.props.listing}
            additionalClasses={"md-hide xs-mt1"}
            context="schedule"
          />
        </a>
      </div>
    );
  }
}
