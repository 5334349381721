import humps from "humps";
import * as R from "ramda";

export const BOUNDS_MIN = "min"
export const BOUNDS_MAX = "max"

export const camelizeResponse = data => {
  if (Array.isArray(data)) {
    return data.map(obj => humps.camelizeKeys(obj));
  } else {
    return humps.camelizeKeys(data);
  }
};

export const filterObjectArrayByAttr = (term, attr, ary) =>
  ary.filter(el => el[attr] && el[attr].indexOf(term) > -1);

export const getSubsetOfArray = (array, limit, offset) =>
  array.slice(offset, offset + limit);

export const numberWithCommas = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getQueryStringValue = key => {
  return decodeURIComponent(
    window.location.search.replace(
      new RegExp(
        "^(?:.*[&\\?]" +
          encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
          "(?:\\=([^&]*))?)?.*$",
        "i"
      ),
      "$1"
    )
  );
};

export const rangeStep = (start, step, stop) =>
  R.map(n => start + step * n, R.range(0, (1 + (stop - start) / step) >>> 0));

export const titleCase = str => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export const viewport = (gMapsPlaceObjOrBounds, latOrLngFn, minOrMax) => {
  const geo =
    R.path(["geometry", "viewport"], gMapsPlaceObjOrBounds) ||
    gMapsPlaceObjOrBounds;

  const latsOrLngs = R.pipe(R.keys, latOrLngFn, R.path(R.__, geo))(geo);

  return R.pipe(
    R.keys,
    R.ifElse(ary => minOrMax == BOUNDS_MIN, R.take(1), R.takeLast(1)),
    R.path(R.__, latsOrLngs)
  )(latsOrLngs);
};

export const viewportLat = (gMapsPlaceObjOrBounds, minOrMax) =>
  viewport(gMapsPlaceObjOrBounds, R.take(1), minOrMax);
export const viewportLng = (gMapsPlaceObjOrBounds, minOrMax) =>
  viewport(gMapsPlaceObjOrBounds, R.takeLast(1), minOrMax);
