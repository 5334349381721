import {types, onSnapshot, getEnv, flow, getRoot} from "mobx-state-tree";
import * as R from "ramda";

export const AgentModel = types
    .model("AgentModel")
    .props({
        id: types.optional(types.number, 0),
        firstName: types.optional(types.string, ""),
        lastName: types.optional(types.string, ""),
        vNumber: types.optional(types.string, ""),
        officeLocation: types.optional(types.string, ""),
        phone: types.maybeNull(types.string),
        email: types.optional(types.string, ""),
        imageUrl: types.maybeNull(types.string),
        slug: types.optional(types.string, ""),
        prec: types.optional(types.boolean, false),
        propertyManagerLicensed: types.optional(types.boolean, false),
        pronoun: types.maybeNull(types.string),
        showAgentTitle: types.optional(types.boolean, false)
    })
    .views(self => ({
        get fullName() {
            return `${self.firstName} ${self.lastName}`;
        },
        get fullNameDowncase() {
            return self.fullName.toLowerCase();
        },
        get officeLocationDowncase() {
            return self.officeLocation.toLowerCase();
        },
        get agentUrl() {
            return `/${self.slug}`;
        }
    }));
