import React from "react";
import * as R from "ramda";

import { inject, observer } from "mobx-react";

@inject("listingStore")
@observer
export class FavouriteButton extends React.Component {
  handleFavouriteButtonClicked = e => {
    e.preventDefault();

    const {
      favourited,
      favouriteListing,
      unfavouriteListing
    } = this.props.listing;

    favourited ? unfavouriteListing() : favouriteListing();
  };

  render() {
    const {
      additionalClasses = "",
      isMobileButton,
      hideButtonText
    } = this.props;
    const { favourited } = this.props.listing;
    let favouriteButtonClass = favourited
      ? "listing-card__remove-button"
      : "listing-card__save-button";

    if (isMobileButton) {
      favouriteButtonClass += ` ${favouriteButtonClass}--mobile`;
    }

    let favouriteButtonText;
    if (!hideButtonText) {
      favouriteButtonText = favourited ? "Remove" : "Save";
    }

    return (
      <button
        className={`${favouriteButtonClass} ${additionalClasses}`}
        onClick={e => this.handleFavouriteButtonClicked(e)}
      >
        {favouriteButtonText}
      </button>
    );
  }
}
