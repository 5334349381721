import React from "react";
import * as R from "ramda";

import { inject, observer } from "mobx-react";
import { CompactListingCard } from "./compact-listing-card";

@inject("listingStore")
@observer
export class FavouritesList extends React.Component {
  handleFavouriteButtonClicked = e => {
    const { toggleShowFavourites } = this.props.listingStore;
    toggleShowFavourites();
  };

  renderFavouritelistingCards = () => {
    const { favouriteListings } = this.props.listingStore;

    return favouriteListings.map(listing => {
      return (
        <div className="favourite-listing-card-container">
          <CompactListingCard
            listing={listing}
            key={listing.id}
            includeScheduleButton={true}
            additionalClassNames="favourite-listing-card xs-py2"
          />
        </div>
      );
    });
  };

  render() {
    const { currentUserLoggedIn, showFavourites } = this.props.listingStore;
    const showFavouritesClass = showFavourites ? "" : "xs-hide";

    const favouritesListLoginShowClass = currentUserLoggedIn
      ? "xs-hide"
      : "xs-block";

    return [
      <div
        key="favourites-overlay"
        className={`favourites-overlay ${showFavouritesClass}`}
        onClick={e => this.handleFavouriteButtonClicked()}
      />,
      <div
        key="favourites-list"
        className={`favourites-list ${showFavouritesClass}`}
      >
        <button
          className="favourites-list__toggle-button"
          onClick={e => this.handleFavouriteButtonClicked(e)}
        >
          <i className="fa fa-heart xs-mr1" />My Favourites
        </button>
        <div className="xs-relative">
          {this.renderFavouritelistingCards()}
          <div
            className={`favourites-list__sign-up ${favouritesListLoginShowClass}`}
          >
            <div className="favourites-list__sign-up-intro">
              <p className="xs-mb1">Enjoying Oakwyn?</p>
              <p className="xs-mb2">
                Please log in or sign up to save more favourites!
              </p>
              <p className="text-brand xs-mb2">
                <i className="fa fa-heart-o xs-mr1" />
                <i className="fa fa-heart-o xs-mr1" />
                <i className="fa fa-heart-o" />
              </p>
              <div className="favourites-social-login xs-hide">
                <p className="favourites-list__sign-up-heading xs-mb3">
                  Log in with
                </p>
                <button className="social-sign-in-button social-sign-in-button--google xs-mb2">
                  Google
                </button>
                <button className="social-sign-in-button social-sign-in-button--facebook">
                  Facebook
                </button>
                <div className="divider divider--with-text xs-my4">
                  <span className="divider-or">OR</span>
                </div>
              </div>
              <a
                href="/sign_in"
                className=" favourites-list__login-button xs-mb2"
              >
                Log In
              </a>
              <a href="/sign_up" className="favourites-list__signup-button">
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </div>
    ];
  }
}
