import React from "react";
import * as R from "ramda";
import {
  useJsApiLoader
} from "@react-google-maps/api";
import { inject, observer } from "mobx-react";
import { SearchHeader } from "./search-header";
import { SearchFilters } from "./search-filters";
import { SearchResults } from "./search-results";
import { FavouritesList } from "./favourites-list";
import { MapView } from "./map-view";
import { Pagination } from "../../shared/pagination";
import { PaginationSummary } from "../../shared/pagination-summary";
import { ListingContactModal } from "../../shared/listing-contact-modal";
import { OmittedSearchResultsMessage } from "../property-search/omitted-search-results-message";

const INITIAL_ZOOM_LEVEL = 13;
const GMAPS_LIBRARY = ["places"]

export const PropertySearch = inject("listingStore")(observer((props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries: GMAPS_LIBRARY
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);

    // setMap(map)
    const {
      boundsMinLatitude,
      boundsMinLongitude,
      boundsMaxLatitude,
      boundsMaxLongitude
    } = props.listingStore;
    const initialBounds = new window.google.maps.LatLngBounds(
      { lat: boundsMinLatitude, lng: boundsMinLongitude },
      { lat: boundsMaxLatitude, lng: boundsMaxLongitude }
    );
    // set padding to -1 to avoid zoom out
    map.fitBounds(initialBounds, -1);
    map.setZoom(INITIAL_ZOOM_LEVEL)
    

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  
  const onSearchViewToggle = () => {
    const { toggleGridView } = props.listingStore;
    toggleGridView();
  };

  const handleMapToggleClicked = () => {
    const { toggleMobileMapView } = props.listingStore;
    toggleMobileMapView();
  };

  const handlePaginationClick = data => {
    const pageNumber = data.selected;
    props.listingStore.updatePageNumber(pageNumber);
  };

  const handleFiltersToggleClicked = e => {
    const { toggleShowFilters } = props.listingStore;
    toggleShowFilters();
  };

  const {
    menuIsOpen,
    mobileMapIsVisible,
    pageNumber,
    pageCount,
    currentListingsCount,
    totalListings,
    selectedMapListingData,
    showGridView,
    showOmittedSearchResultsMessage,
    mapIsReady
  } = props.listingStore;
  const noScrollClass = menuIsOpen ? "no-scroll" : "";
  const mobileMapClass = mobileMapIsVisible
    ? ""
    : "hidden-off-screen--mobile-only";
  const mobileMapButtonText = mobileMapIsVisible ? "Hide Map" : "Show Map";
  const gridViewOpenClass = showGridView ? "gallery-open" : "";
  const mapViewClass = mobileMapIsVisible ? "property-search--map-view" : ""

  return (
    <div className={`property-search ${noScrollClass} ${mapViewClass}`}>
      {isLoaded && <SearchHeader
        mapRefObj={map}
        handleFiltersToggleClicked={handleFiltersToggleClicked}
      />}
      <SearchFilters
        handleFiltersToggleClicked={handleFiltersToggleClicked}
      />
      <div className="property-search-mobile-nav">
        <button
          className="button button--brand button--secondary"
          onClick={e => handleMapToggleClicked(e)}
        >
          {mobileMapButtonText}
        </button>
        <Pagination
          context="mobile-search"
          pageNumber={pageNumber}
          pageCount={pageCount}
          handlePaginationClick={handlePaginationClick}
          additionalWrapperClasses={"xs-m0"}
        />
      </div>
      <div className="property-search-results-map-container">
        <OmittedSearchResultsMessage
          visible={showOmittedSearchResultsMessage}
        />
        <div className={`search-results-container ${gridViewOpenClass}`}>
          <PaginationSummary
            currentListingsCount={currentListingsCount}
            totalListings={totalListings}
            additionalClassNames="md-hide xs-mt2"
          />
          <SearchResults />
        </div>

        <div
          className="property-search-gallery-toggle xs-hide md-flex"
          onClick={e => onSearchViewToggle()}
        >
          <button className="button button--side-toggle">
            <i className="fa fa-chevron-right" />
          </button>
        </div>
        {mapIsReady && (
          <MapView
            zoom={INITIAL_ZOOM_LEVEL}
            containerClass={`property-search-map ${mobileMapClass}`}
            isLoaded={isLoaded}
            map={map}
            onLoad={onLoad}
            onUnmount={onUnmount}
          />
        )}
      </div>
      <Pagination
        context="search"
        pageNumber={pageNumber}
        pageCount={pageCount}
        handlePaginationClick={handlePaginationClick}
        currentListingsCount={currentListingsCount}
        totalListings={totalListings}
      />
      <FavouritesList />
      <ListingContactModal />
    </div>
  );

}))
