import React from "react";
import * as R from "ramda";
import ReactPaginate from "react-paginate";
import { PaginationSummary } from "./pagination-summary";

export class Pagination extends React.Component {
  render() {
    const {
      context,
      pageNumber,
      pageCount,
      handlePaginationClick,
      currentListingsCount,
      totalListings,
      additionalWrapperClasses // For the wrapper element
    } = this.props;

    let paginationWrapperClass = "";
    let paginationContainerClass = "";
    let paginationButtonClass = "";
    let breakLabel = "...";

    // We want to hide the next/prev buttons in most cases except mobile
    let prevButtonClass = "xs-hide";
    let nextButtonClass = "xs-hide";

    if (context == "search") {
      paginationWrapperClass = "pagination-wrapper--search";
      paginationContainerClass = "xs-mr-auto";
      paginationButtonClass = "button button--pagination-transparent";
    } else if (context == "mobile-search") {
      paginationButtonClass = "xs-hide";
      prevButtonClass = "pagination-button--prev";
      nextButtonClass = "pagination-button--next";
      breakLabel = "";
    } else if (context == "agents") {
      paginationButtonClass = "button button--pagination";
    }

    return (
      <div
        className={`pagination-wrapper ${paginationWrapperClass} ${additionalWrapperClasses}`}
      >
        {context != "mobile-search" && (
          <span className="pagination-label">Page</span>
        )}
        <ReactPaginate
          previousLabel={<i className="fa fa-chevron-left" />}
          nextLabel={<i className="fa fa-chevron-right" />}
          breakLabel={breakLabel}
          breakClassName={"text-gray-lightest"}
          forcePage={pageCount <= 0 ? -1 : pageNumber}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePaginationClick}
          containerClassName={`pagination list-unstyled ${paginationContainerClass}`}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          pageLinkClassName={`${paginationButtonClass}`}
          previousLinkClassName={prevButtonClass}
          nextLinkClassName={nextButtonClass}
          activeClassName={"active"}
          disabledClassName={"button--disabled"}
        />
        {context == "search" && (
          <PaginationSummary
            currentListingsCount={currentListingsCount}
            totalListings={totalListings}
          />
        )}
      </div>
    );
  }
}
