import { Api } from "./api";
import { RootStoreModel } from "../stores/root-store";

export const setupRootStore = () => {
  const api = new Api();
  const initialState = {};
  const rootStore = RootStoreModel.create(initialState, { api });

  return rootStore;
};
