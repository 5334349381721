import { types, onSnapshot, getEnv, flow, getRoot } from "mobx-state-tree";
import { AgentStoreModel } from "./agent-store";
import { ListingStoreModel } from "./listing-store";
import * as R from "ramda";

export const RootStoreModel = types
  .model("RootStoreModel")
  .props({
    agentStore: types.optional(AgentStoreModel, {}),
    listingStore: types.optional(ListingStoreModel, {})
  })
  .actions(self => ({
    // startup: flow(function*() {
    //   //startup code goes here
    //   console.log("hello");
    // }),

    afterCreate() {
      // self.startup();
    }
  }));
