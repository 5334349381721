import React from "react";
import * as R from "ramda";

import { inject, observer } from "mobx-react";

@inject("agentStore")
@observer
export class AgentSearch extends React.Component {
  handleInputChanged = value => {
    this.props.agentStore.setSearchTerm(value);
  };

  handleSelectChanged = value => {
    this.props.agentStore.setOfficeId(value);
  };

  onSubmit = e => {
    e.preventDefault();
  };

  renderOfficeOptions = defaultLabel => {
    const { offices } = this.props.agentStore;

    return [
      <option key="default" value="">
        {defaultLabel}
      </option>,
      R.map(
        office => (
          <option key={office.id} value={office.id}>
            {office.officeTitle}
          </option>
        ),
        offices
      )
    ];
  };

  render() {
    const { officeId } = this.props.agentStore;

    return (
      <div className="xs-border-top xs-border-bottom xs-mb3">
        <form
          className="container container--large sm-flex sm-flex-justify-space-between xs-py0"
          onSubmit={e => this.onSubmit(e)}
        >
          <select
            className="agent-filters-select form-brand__item form-brand-select xs-my1"
            onChange={e => this.handleSelectChanged(e.target.value)}
            value={officeId || ""}
          >
            {this.renderOfficeOptions("Select Office")}
          </select>
          <div className="xs-flex sm-border-left xs-py1">
            <input
              type="text"
              className="text-input xs-flex-grow-1 rounded xs-border-none"
              placeholder="Find Agent"
              onChange={e => this.handleInputChanged(e.target.value)}
            />
            <div className="icon-square rounded">
              <i className="fa fa-search xs-text-3 text-brand" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
