import React from "react";
import * as R from "ramda";

import { inject, observer } from "mobx-react";

@inject("listingStore")
@observer
export class ScheduleButton extends React.Component {
  handleClick = (e, context) => {
    e.preventDefault();
    const {
      currentListingId,
      setContactModalContext,
      toggleListingContactModal
    } = this.props.listingStore;

    let id;

    if (this.props.listing) {
      id = this.props.listing.id;
    } else if (currentListingId) {
      id = currentListingId;
    }

    toggleListingContactModal(id);
    setContactModalContext(context);
  };

  render() {
    const { additionalClasses, context } = this.props;

    let buttonText;

    if (context == "requestInfo") {
      buttonText = "Request More Information";
    } else {
      buttonText = "Schedule a Showing";
    }

    return (
      <button
        className={`listing-schedule-button ${additionalClasses}`}
        onClick={e => this.handleClick(e, context)}
      >
        {buttonText}
      </button>
    );
  }
}
