import { types, onSnapshot, getEnv, flow, getRoot } from "mobx-state-tree";
import * as R from "ramda";

export const OfficeModel = types
  .model("OfficeModel")
  .props({
    id: types.optional(types.number, 0),
    officeTitle: types.optional(types.string, "")
  })
  .views(self => ({}));
