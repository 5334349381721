import React from "react";
import { inject, observer } from "mobx-react";
import { titleCase } from "../lib/utils";

@inject("listingStore")
@observer
export class ListingContactModal extends React.Component {
  renderFormInput = (name, type, placeholderText, required = true) => {
    const requiredAttribute = required ? true : false;
    return (
      <div className="col xs-col-12 md-col-6">
        <input
          type={type}
          name={name}
          placeholder={placeholderText}
          required={requiredAttribute}
          className="form-brand__item form-brand-text-input"
        />
      </div>
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      id,
      scheduleShowing,
      requestInfo
    } = this.props.listingStore.contactModalListingData;

    const { contactModalContext } = this.props.listingStore;

    if (contactModalContext == "schedule") {
      scheduleShowing();
    } else {
      requestInfo();
    }
  };

  handleClose = e => {
    this.props.listingStore.toggleListingContactModal();
  };

  render() {
    const {
      contactModalContext,
      contactSubmitMessage,
      showListingContactModal,
      contactModalListingData,
      toggleScheduleListingModal
    } = this.props.listingStore;

    let address, mlNumber;
    if (contactModalListingData) {
      address = contactModalListingData.address;
      mlNumber = contactModalListingData.mlNumber;
    }

    let mainHeadingText = "";
    let showModalClass = "modal--visible";
    if (contactModalContext == "schedule") {
      mainHeadingText = "Schedule Showing For";
      showModalClass = showListingContactModal ? "modal--visible" : "";
    } else if (contactModalContext == "requestInfo") {
      mainHeadingText = "Request More Information For";
      showModalClass = showListingContactModal ? "modal--visible" : "";
    }

    const submitMessageShowClass = contactSubmitMessage
      ? "xs-block"
      : "xs-hide";

    return (
      contactModalListingData && (
        <div className={`modal ${showModalClass}`}>
          <div className="listing-contact-modal modal__content md-mt5">
            <a
              className="modal__close js-hide-modal"
              href="javascript:"
              onClick={e => this.handleClose()}
            >
              <svg
                viewBox="0 0 38 38"
                className="modal__close-icon"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Close</title>
                <path d="M30.3 10.5l-2.8-2.8-8.5 8.5-8.5-8.5-2.8 2.8 8.5 8.5-8.5 8.5 2.8 2.8 8.5-8.5 8.5 8.5 2.8-2.8-8.5-8.5z" />
              </svg>
            </a>
            <div className="listing-contact-modal__heading-container">
              <h1 className="listing-contact-modal__heading">
                {mainHeadingText}
              </h1>
            </div>
            <div className="listing-contact-modal__subheading-container">
              <div>{address && titleCase(address)}</div>
              <div className="listing-contact-modal__mls-id">
                Listing ID: {mlNumber && mlNumber}
              </div>
            </div>
            <form
              className="listing-contact-modal__form clearfix gutters"
              onSubmit={e => this.handleSubmit(e)}
            >
              {this.renderFormInput("first_name", "text", "First Name:")}
              {this.renderFormInput("last_name", "text", "Last Name:")}
              {this.renderFormInput("email", "email", "Email:")}
              {this.renderFormInput("phone", "tel", "Tel:", false)}
              <div className="col xs-col-12">
                <textarea
                  className="form-brand__item form-brand-textarea xs-mb0"
                  placeholder="Optional Message"
                  name="message"
                />
              </div>
              <div
                className={`col xs-col-12 listing-contact-modal__action-container `}
              >
                <div className={`${submitMessageShowClass} xs-mt4`}>
                  {contactSubmitMessage}
                </div>
                <div className="xs-mt4">
                  <button
                    className="listing-contact-modal__cancel-button xs-mr1 js-hide-modal"
                    onClick={e => this.handleClose()}
                  >
                    Cancel
                  </button>
                  <input
                    type="submit"
                    value="Submit"
                    className="button button--brand xs-ml1"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      )
    );
  }
}
