import { create } from "apisauce";
import * as R from "ramda";

import { ApiConfig } from "./api-config.js";

export const BASE_URL = import.meta.env.VITE_API_BASE_URL || "http://localhost:3000";
export class Api {
  constructor(baseURL = BASE_URL) {
    this.client = create({
      baseURL,
      headers: {
        Accept: "application/vnd.oakwyn_online.system",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Authorization: `Token token="${ApiConfig.apiKeyToken}"`
      }
    });

    this.getListingsSource = null;
  }

  addMonitor(monitor) {
    this.client.addMonitor(monitor);
  }

  async getAgents(params) {
    return this.client.get(`/agents`, params);
  }

  async getListingData(listingId) {
    return this.client.get(`/oakwyn_rets_listings/${listingId}`);
  }

  async getMultipleListingsData(listingIds) {
    return this.client.get(`/oakwyn_rets_listings/show_multiple`, { ids: listingIds });
  }

  async getListings(params) {
    if (this.getListingsSource) {
      this.getListingsSource.abort();
    }

    this.getListingsSource = new AbortController();;

    return this.client.post(`/oakwyn_rets_listings/search`, params, {
      signal:this.getListingsSource.signal
    });
  }

  async getFavouriteListings() {
    return this.client.get(`/oakwyn_rets_listings/favourites`);
  }

  async favouriteListing(listingId, authenticityToken) {
    const params = {
      oakwyn_rets_listing_id: listingId,
      authenticity_token: authenticityToken
    };
    return this.client.post(`/favourites.json`, params);
  }

  async unfavouriteListing(favouriteId, authenticityToken) {
    return this.client.delete(`/favourites/${favouriteId}.json`, {
      authenticity_token: authenticityToken
    });
  }

  // scheduleShowing and requestInfo are identical, but we may end up changing them
  async scheduleShowing(listingId, params) {
    return this.client.post(
      `/oakwyn_rets_listings/${listingId}/schedule_showing`,
      params
    );
  }

  async requestInfo(listingId, params) {
    return this.client.post(
      `/oakwyn_rets_listings/${listingId}/request_info`,
      params
    );
  }
}
