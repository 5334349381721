import React from "react";
import { Provider } from "mobx-react";
import { setupRootStore } from "./services/setup";

export class RootStoreWrapper extends React.Component {
  // use class properties instead of constructors now (https://hackernoon.com/the-constructor-is-dead-long-live-the-constructor-c10871bea599)
  state = { rootStore: null };

  componentWillMount() {
    let rootStore;
    if (window.rootStore) {
      rootStore = window.rootStore;
    } else {
      rootStore = setupRootStore();
      window.rootStore = rootStore;
    }
    this.setState({ rootStore });
  }

  render() {
    return <Provider {...this.state.rootStore}>{this.props.children}</Provider>;
  }
}
