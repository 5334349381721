import React from 'react';
import { inject, observer } from 'mobx-react';

import { CloseButton } from '../../shared/close-button';

@inject('listingStore')
@observer
export class OmittedSearchResultsMessage extends React.Component {
  constructor(props) {
    super(props);
    this.dismiss = this.dismiss.bind(this);
  }

  dismiss() {
    const { dismissOmittedSearchResultsMessage } = this.props.listingStore;
    dismissOmittedSearchResultsMessage();
  }

  render() {
    const {
      showOmittedSearchResultsMessage,
      totalListings
    } = this.props.listingStore;
    if (showOmittedSearchResultsMessage) {
      return (
        <div className="property-search-results-map-container__omitted-results-message">
          <div>
            1000 of {totalListings} listings in the search area shown. Zoom in
            on your area of interest to see more listings.
          </div>
          <CloseButton
            containerClass="xs-ml1"
            xClass="close-button__x_white"
            clickHandler={e => this.dismiss}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}
