import React from "react";
import * as R from "ramda";

export class PaginationSummary extends React.Component {
  render() {
    const {
      currentListingsCount,
      totalListings,
      additionalClassNames
    } = this.props;

    return (
      <div
        className={`property-search-pagination-summary ${additionalClassNames}`}
      >
        Showing {currentListingsCount} of {totalListings}
        <span className="xs-hide md-inline"> results</span>
      </div>
    );
  }
}
