import { types, onSnapshot, getEnv, flow, getRoot } from "mobx-state-tree";
import * as R from "ramda";

import { numberWithCommas } from "../lib/utils";

export const ImageModel = types
  .model("ImageModel")
  .props({
    id: types.identifierNumber,
    description: types.maybeNull(types.string),
    url: types.optional(types.string, ""),
    thumbUrl: types.optional(types.string, "")
  })
  .views(self => ({}));
