import { types, onSnapshot, getEnv, flow, getRoot } from "mobx-state-tree";
import * as R from "ramda";

import { AgentModel } from "../models/agent";
import { OfficeModel } from "../models/office";
import {
  camelizeResponse,
  filterObjectArrayByAttr,
  getSubsetOfArray,
  getQueryStringValue
} from "../lib/utils";

export const PAGINATION_LIMIT = 12;

export const AgentStoreModel = types
  .model("AgentStoreModel")
  .props({
    agents: types.optional(types.array(AgentModel), []),
    offices: types.optional(types.array(OfficeModel), []),
    totalAgents: types.optional(types.number, 0),
    searchTerm: types.maybeNull(types.string),
    officeId: types.maybeNull(types.number),
    pageNumber: types.optional(types.number, 0),
    apiPageNumber: types.optional(types.number, 0)
  })
  .views(self => ({
    get pageCount() {
      return Math.ceil(self.totalAgents / PAGINATION_LIMIT);
    }
  }))
  .actions(self => ({
    setSearchTerm(searchTerm) {
      self.searchTerm = searchTerm.toLowerCase();
      self.updatePageNumber(0);
      self.getAgents();
    },
    setOfficeId(officeId) {
      self.officeId = officeId ? Number(officeId) : null;
      self.updatePageNumber(0);
      self.getAgents();
    },
    setInitialOfficeIdFromUrl() {
      const officeIdParam = getQueryStringValue("office_id");
      if (officeIdParam) {
        self.setOfficeId(officeIdParam);
      }
    },
    updatePageNumber(pageNumber) {
      self.pageNumber = pageNumber;
      self.apiPageNumber = pageNumber + 1;
      self.getAgents();
    },
    getAgents: flow(function*() {
      const env = getEnv(self);
      const response = yield env.api.getAgents({
        office_id: self.officeId,
        search_term: self.searchTerm,
        per: PAGINATION_LIMIT,
        page: self.apiPageNumber
      });

      if (response.ok) {
        self.agents = camelizeResponse(response.data["agents"]);
        self.totalAgents = response.data.meta["total_count"];
        self.offices = camelizeResponse(response.data["offices"]);
      } else {
        // error message
      }
    }),

    afterCreate() {
      self.setInitialOfficeIdFromUrl();
      self.getAgents();
    }
  }));
