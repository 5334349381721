import React from "react";
import * as R from "ramda";

import { inject, observer } from "mobx-react";
import { AgentCard } from "./agent-card";
import { AgentSearch } from "./agent-search";
import { Pagination } from "../../shared/pagination";

@inject("agentStore")
@observer
export class AgentsListing extends React.Component {
  renderAgents = () => {
    const { agents } = this.props.agentStore;

    return agents.map(agent => {
      return <AgentCard agent={agent} key={agent.id} />;
    });
  };

  renderPagination = additionalWrapperClasses => {
    const { pageNumber, pageCount } = this.props.agentStore;

    return (
      <Pagination
        context="agents"
        pageNumber={pageNumber}
        pageCount={pageCount}
        handlePaginationClick={this.handlePaginationClick}
        additionalWrapperClasses={additionalWrapperClasses}
      />
    );
  };

  handlePaginationClick = data => {
    const pageNumber = data.selected;
    this.props.agentStore.updatePageNumber(pageNumber);
  };

  render() {
    return (
      <div className="">
        <AgentSearch />
        <div className="container container--large">
          {this.renderPagination("xs-mb4")}
          <ul className="agents-list clearfix gutters">
            {this.renderAgents()}
          </ul>
          {this.renderPagination()}
        </div>
      </div>
    );
  }
}
