import React from "react";
import * as R from "ramda";
import Autocomplete from "react-google-autocomplete";

import { BC_SEARCH_BOUNDS } from "../../stores/listing-store";
import { inject, observer } from "mobx-react";
import { FavouritesListToggleButton } from "./favourites-list-toggle-button";
import {
  viewportLat,
  viewportLng,
  BOUNDS_MIN,
  BOUNDS_MAX
} from "../../lib/utils";
import { GOOGLE_MAPS_PLACES_FIELDS, GOOGLE_MAPS_PLACES_TYPES } from '../../services/constants'

@inject("listingStore")
@observer
export class SearchHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bcBounds: new window.google.maps.LatLngBounds(
        BC_SEARCH_BOUNDS[0],
        BC_SEARCH_BOUNDS[1]
      )
    };
  }

  onPlaceSelected = place => {
    const { mapRefObj } = this.props;
    const minLat = viewportLat(place, BOUNDS_MIN);
    const minLng = viewportLng(place, BOUNDS_MIN);
    const maxLat = viewportLat(place, BOUNDS_MAX);
    const maxLng = viewportLng(place, BOUNDS_MAX);
  
    const { updateBounds, setSearchTerm } = this.props.listingStore;
    updateBounds(minLat, minLng, maxLat, maxLng);
    setSearchTerm(place.formatted_address);
    const resultsBounds = new window.google.maps.LatLngBounds(
      { lat: minLat, lng: minLng },
      { lat: maxLat, lng: maxLng }
    );

    mapRefObj && mapRefObj.fitBounds(resultsBounds, -1);
  };

  render() {
    const { bcBounds } = this.state;
    const { handleFiltersToggleClicked } = this.props;
    const {
      numFiltersOn,
      orderByDirection,
      setProp,
      setSearchTerm,
      searchTerm
    } = this.props.listingStore;
    return (
      <div className="property-search-header">
        <a
          href="/"
          className="property-search-header-logo xs-flex-order-1 xs-mb1 md-mb0 md-mr1"
        />
        <span className="property-search-location-wrapper xs-flex-order-4 md-flex-order-2 xs-col-12 md-col-4 xs-mt1 md-mt0 md-mr2">
          <Autocomplete
            onPlaceSelected={
              this.onPlaceSelected // style={{ width: "90%" }}
            }
            className="text-input property-search-location-input form-brand__item xs-mb0"
            placeholder="Address, City, Neighbourhood, Postal Code"
            types={[]}
            options={{
              componentRestrictions:{
                country: "CA"
              },
              types: GOOGLE_MAPS_PLACES_TYPES,
              fields: GOOGLE_MAPS_PLACES_FIELDS
            }}
            bounds={bcBounds}
            onChange={e => {
              setSearchTerm(e.target.value);
            }}
            value={searchTerm}
          />
          <button
            type="submit"
            className="button button--brand xs-text-6 xs-ml1"
          >
            <i className="fa fa-search" />
            <span className="xs-hide md-inline md-ml1">search</span>
          </button>
        </span>
        <div className="property-search-header-sort-wrapper xs-flex-order-2 md-flex-order-3 xs-mb1 md-mb0">
          <div className="xs-hide md-block">
            <div>Sort Results by</div>
            <select
              className="property-search-header-sort-select select"
              onChange={e => setProp("orderByDirection", e.target.value)}
              value={orderByDirection || "asc"}
            >
              <option value="asc">Price (low - high)</option>
              <option value="desc">Price (high - low)</option>
            </select>
          </div>
          <button
            className={`property-search-header__filters-button ${
              numFiltersOn == 0 ? "button--secondary " : ""
            } md-hide xs-mr1`}
            onClick={e => handleFiltersToggleClicked(e)}
          >
            Filters {numFiltersOn > 0 ? `(${numFiltersOn})` : ""}
          </button>
        </div>
        <FavouritesListToggleButton additionalClassNames="xs-flex-order-3 md-flex-order-4 button--secondary xs-mr1 xs-mb1 md-mb0" />
      </div>
    );
  }
}
