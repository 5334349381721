import React from "react";
import Autocomplete from "react-google-autocomplete";

import { BC_SEARCH_BOUNDS } from "../../stores/listing-store";
import { inject, observer } from "mobx-react";
import {
  viewportLat,
  viewportLng,
  BOUNDS_MIN,
  BOUNDS_MAX
} from "../../lib/utils";
import { GOOGLE_MAPS_PLACES_FIELDS, GOOGLE_MAPS_PLACES_TYPES } from '../../services/constants'

@inject("listingStore")
@observer
export class AutocompleteSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bcBounds: new window.google.maps.LatLngBounds(
        BC_SEARCH_BOUNDS[0],
        BC_SEARCH_BOUNDS[1]
      )
    };
  }

  onPlaceSelected = place => {
    const { setSearchTerm, updateBounds } = this.props.listingStore;

    setSearchTerm(place.formatted_address);
    const minLat = viewportLat(place, BOUNDS_MIN);
    const minLng = viewportLng(place, BOUNDS_MIN);
    const maxLat = viewportLat(place, BOUNDS_MAX);
    const maxLng = viewportLng(place, BOUNDS_MAX);

    updateBounds(minLat, minLng, maxLat, maxLng);
    window.location = `${window.location.origin}/search`;
  };

  render() {
    const { bcBounds } = this.state;
    return [
      <span className="property-search-location-wrapper">
        <Autocomplete
          // style={{ width: "90%" }}
          onPlaceSelected={this.onPlaceSelected}
          className="text-input property-search-location-input form-brand__item"
          placeholder="Address, City, Neighbourhood, Postal Code"
          types={[]}
          options={{
            componentRestrictions:{
              country: "CA"
            },
            types: GOOGLE_MAPS_PLACES_TYPES,
            fields: GOOGLE_MAPS_PLACES_FIELDS
          }}
          bounds={bcBounds}
        />
        <button type="submit" className="button button--brand xs-text-6 xs-ml1">
          <i className="fa fa-search" />
          <span className="xs-hide md-inline md-ml1">search</span>
        </button>
      </span>
    ];
  }
}
