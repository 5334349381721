import { types, onSnapshot, getEnv, flow, getRoot } from "mobx-state-tree";
import * as R from "ramda";

import { numberWithCommas } from "../lib/utils";
import { ImageModel } from "./image";

export const ListingModel = types
  .model("ListingModel")
  .props({
    id: types.identifierNumber,
    address: types.maybeNull(types.string),
    neighbourhood: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    areaTotal: types.maybeNull(types.number),
    bathsTotal: types.maybeNull(types.number),
    bedroomsTotal: types.maybeNull(types.number),
    description: types.maybeNull(types.string),
    propertyType: types.maybeNull(types.string),
    latitude: types.maybeNull(types.number),
    longitude: types.maybeNull(types.number),
    mlNumber: types.maybeNull(types.string),
    price: types.maybeNull(types.number),
    humanizedPrice: types.maybeNull(types.string),
    coverImageUrl: types.maybeNull(types.string),
    images: types.maybeNull(types.array(ImageModel), []),
    favouriteId: types.maybeNull(types.number),
    favouriteAuthenticityToken: types.maybeNull(types.string),
    slug: types.maybeNull(types.string)
  })
  .views(self => ({
    get favourited() {
      const { listingStore } = getRoot(self);
      return R.any(
        listing => listing.id == self.id,
        listingStore.favouriteListings
      );
    },
    get shortPropertyType() {
      return self.propertyType == "Apartment/Condo"
        ? "Condo"
        : self.propertyType;
    },
    get formattedPrice() {
      // currently unused, using humanizedPrice instead
      return numberWithCommas(self.price);
    }
  }))
  .actions(self => ({
    favouriteListing: flow(function*() {
      const env = getEnv(self);
      const { listingStore } = getRoot(self);
      const response = yield env.api.favouriteListing(
        self.id,
        self.favouriteAuthenticityToken
      );

      if (response.ok) {
        const favourite = response.data;
        self.favouriteId = favourite.id;
        listingStore.getFavouriteListings();
      } else if (response.status == 401) {
        // you aren't logged in, show the modal
        eval(response.data);
      } else {
        console.log("unable to save");
      }
    }),
    unfavouriteListing: flow(function*() {
      const env = getEnv(self);
      const { listingStore } = getRoot(self);
      const response = yield env.api.unfavouriteListing(
        self.favouriteId,
        self.favouriteAuthenticityToken
      );

      if (response.ok) {
        self.favouriteId = null;
        listingStore.getFavouriteListings();
      } else {
        console.log("unable to unfavourite");
      }
    }),
    contactFormData() {
      let formData = {};
      const formInputs = [
        ...document.querySelectorAll(
          ".listing-contact-modal__form .form-brand__item"
        )
      ];

      formInputs.map(input => (formData[input.name] = input.value));
      formData["oakwyn_rets_listing_id"] = self.id;
      return formData;
    },
    scheduleShowing: flow(function*() {
      const { listingStore } = getRoot(self);

      const env = getEnv(self);
      const response = yield env.api.scheduleShowing(
        self.id,
        self.contactFormData()
      );

      if (response.ok) {
        listingStore.setContactSubmitMessage(
          "Your email has been sent! An Oakwyn agent will contact you shortly."
        );
      } else {
        listingStore.setContactSubmitMessage(
          "Oops! Something went wrong. Please check the form and try submitting again."
        );
      }
    }),
    requestInfo: flow(function*() {
      const { listingStore } = getRoot(self);

      const env = getEnv(self);
      const response = yield env.api.requestInfo(
        self.id,
        self.contactFormData()
      );

      if (response.ok) {
        listingStore.setContactSubmitMessage(
          "Your email has been sent! An Oakwyn agent will contact you shortly."
        );
      } else {
        listingStore.setContactSubmitMessage(
          "Oops! Something went wrong. Please check the form and try submitting again."
        );
      }
    })
  }));
