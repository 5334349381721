import React from "react";
import { createRoot } from "react-dom/client";
import { RootStoreWrapper } from "../src/root-store-wrapper";
import { AgentsListing } from "../src/domains/agents-index/agents-listing";
import { PropertySearch } from "../src/domains/property-search/property-search";
import { FavouritesList } from "../src/domains/property-search/favourites-list";
import { FavouritesListToggleButton } from "../src/domains/property-search/favourites-list-toggle-button";
import { AutocompleteSearch } from "../src/domains/property-search/autocomplete-search";
import { ListingContactModal } from "../src/shared/listing-contact-modal";
import { ListingScheduleRequestButtons } from "../src/domains/listing/listing-schedule-request-buttons";

const renderIfElementByIdExists = (component, domId) => {
  const el = document.getElementById(domId);
  if (el) {
    const root = createRoot(el)
    root.render(component);
  }
};

document.addEventListener("turbolinks:load", () => {
  setup();
});

const setup = () => {
  const agentsListing = (
    <RootStoreWrapper>
      <AgentsListing />
    </RootStoreWrapper>
  );
  renderIfElementByIdExists(agentsListing, "agents-listing");

  const propertySearch = (
    <RootStoreWrapper>
      <PropertySearch />
    </RootStoreWrapper>
  );
  renderIfElementByIdExists(propertySearch, "property-search");

  // Listing Show Components
  const listingFavouritesButton = (
    <RootStoreWrapper>
      <FavouritesListToggleButton />
    </RootStoreWrapper>
  );

  renderIfElementByIdExists(listingFavouritesButton, "js-listing-favourites-button");

  const listingFavouritesList = (
    <RootStoreWrapper>
      <FavouritesList />
    </RootStoreWrapper>
  );

  renderIfElementByIdExists(listingFavouritesList, "js-listing-favourites-list");

  const listingContactModal = (
    <RootStoreWrapper>
      <ListingContactModal />
    </RootStoreWrapper>
  );

  renderIfElementByIdExists(listingContactModal, "js-listing-contact-modal");

  const listingContactButtonWrapperElement = document.getElementById("js-listing-schedule-request-buttons");

  if (listingContactButtonWrapperElement) {
    const disableButtons = listingContactButtonWrapperElement.dataset.disableButtons;

    const listingScheduleRequestButtons = (
      <RootStoreWrapper>
        <ListingScheduleRequestButtons disableButtons={disableButtons} />
      </RootStoreWrapper>
    );

    renderIfElementByIdExists(listingScheduleRequestButtons, "js-listing-schedule-request-buttons");
  }

  // End Listing Show

  const autocompleteSearch = (
    <RootStoreWrapper>
      <AutocompleteSearch />
    </RootStoreWrapper>
  );
  renderIfElementByIdExists(autocompleteSearch, "autocomplete-search");
};
