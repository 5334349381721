import React from "react";

import { inject, observer } from "mobx-react";
import { ListingCard } from "./listing-card";

@inject("listingStore")
@observer
export class MobileMapInfoWindow extends React.Component {
  handleMobileMapInfoWindowCloseClicked = e => {
    const { closeMobileMapInfoWindow, clearSelectedMapListingData, clearDetachedInfoWindowListingData, updateSelectedMapListingId } = this.props.listingStore;
    closeMobileMapInfoWindow();
    // On close, clear out the listing(s) being viewed. We also do this when we click/open a marker to ensure that there is only ever a single listing's data or multiple listings' data, but not both. Below when we render the ListingCard, it is assumed that only one or the other will exist.
    updateSelectedMapListingId(null);
    clearSelectedMapListingData();
    clearDetachedInfoWindowListingData();
  };

  render() {
    const {
      selectedMapListingData,
      showMobileMapInfoWindow,
      detachedInfoWindowListingData
    } = this.props.listingStore;
    const showMobileMapInfoWindowClass = showMobileMapInfoWindow
      ? ""
      : "xs-hide";

    return [
      <div
        key="mobile-map-info-window"
        className={`mobile-map-info-window ${showMobileMapInfoWindowClass} md-hide`}
      >
        <button
          className="mobile-map-info-window__close-button"
          onClick={e => this.handleMobileMapInfoWindowCloseClicked(e)}
        >
          <i className="fa fa-close" />
        </button>
        { selectedMapListingData && (
          <ListingCard
            listing={selectedMapListingData}
            key={selectedMapListingData.id}
          />
        )}
        { detachedInfoWindowListingData && (
          detachedInfoWindowListingData.map(listing => {
            return <ListingCard
              listing={listing}
              key={listing.id}
            />
          })
        )}
      </div>
    ];
  }
}
