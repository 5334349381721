import React from "react";
import * as R from "ramda";

import { inject, observer } from "mobx-react";

@inject("listingStore")
@observer
export class FavouritesListToggleButton extends React.Component {
  handleFavouriteButtonClicked = e => {
    const { toggleShowFavourites } = this.props.listingStore;
    toggleShowFavourites();
  };

  render() {
    const { additionalClassNames } = this.props;

    return (
      <button
        className={`property-search-header-save-button ${additionalClassNames}`}
        onClick={e => this.handleFavouriteButtonClicked(e)}
      >
        <span className="">
          <span className="xs-hide md-inline">My </span>Favourites
        </span>
      </button>
    );
  }
}
