import React from "react";
import * as R from "ramda";
import { inject, observer } from "mobx-react";
import { FavouriteButton } from "./favourite-button";
import { ScheduleButton } from "./schedule-button";

@inject("listingStore")
@observer
export class CompactListingCard extends React.Component {
  render() {
    const {
      id,
      humanizedPrice,
      address,
      bedroomsTotal,
      bathsTotal,
      neighbourhood,
      city,
      areaTotal,
      shortPropertyType,
      mlNumber,
      coverImageUrl,
      favourited,
      slug
    } = this.props.listing;

    const { additionalClassNames, includeScheduleButton } = this.props;

    return (
      <a
        href={`/properties/${slug}`}
        className={`listing-card compact-listing-card ${additionalClassNames}`}
      >
        <div className="listing-card-image-wrapper compact-listing-card-image-wrapper ar-3-2 xs-mb1">
          {coverImageUrl && (
            <img
              src={coverImageUrl}
              className="aspect-ratio-content aspect-ratio-image"
            />
          )}
        </div>
        <div className="listing-card__price compact-listing-card__price listing-card__price--overlay">
          <span>{humanizedPrice}</span>
        </div>
        <div className="listing-card-details">
          <div className="listing-card__address">{address}</div>
          <div className="listing-card__city xs-mb0">{neighbourhood ? `${neighbourhood}, ${city}` : `${city}`}</div>
          <div className="listing-card-attributes-wrapper listing-card-attributes-wrapper--stacked">
            <span className="listing-card__attribute compact-listing-card__attribute listing-card__beds xs-mr3">
              {bedroomsTotal}
            </span>
            <span className="listing-card__attribute compact-listing-card__attribute listing-card__baths xs-mr3">
              {bathsTotal}
            </span>
            <span className="listing-card__attribute compact-listing-card__attribute listing-card__sqft xs-mr-auto">
              {areaTotal}
            </span>
          </div>
          <div className="listing-card-attributes-wrapper">
            <span className="listing-card__attribute compact-listing-card__attribute listing-card__type xs-mr1">
              {shortPropertyType}
            </span>
            <span className="listing-card__attribute compact-listing-card__attribute listing-card__id xs-mx-auto">
              {mlNumber}
            </span>
            <FavouriteButton listing={this.props.listing} />
          </div>
          {includeScheduleButton && (
            <ScheduleButton
              listing={this.props.listing}
              additionalClasses={"xs-mt1"}
              context="schedule"
            />
          )}
        </div>
      </a>
    );
  }
}
