import React from "react";
import * as R from "ramda";

import { ScheduleButton } from "../property-search/schedule-button";

import { inject, observer } from "mobx-react";

@inject("listingStore")
@observer
export class ListingScheduleRequestButtons extends React.Component {
  componentDidMount = () => {
    let pathArray = window.location.pathname.split("/");
    const { listingStore } = this.props;
    // This component should only be mounted to the listing page (.js-listing-schedule-request-buttons element) anyway, but still a good idea to check the slug
    if (pathArray[pathArray.length - 2] == "properties") {
      let propertySlug = pathArray[pathArray.length - 1];
      listingStore.getCurrentListingDataBySlug(propertySlug);
    }
  };

  render() {
    const { disableButtons } = this.props;

    // The data attribute passes a string, not a boolean
    const disableButtonClass =
      disableButtons == "true" ? "button--disabled" : "";

    return [
      <ScheduleButton
        key="listing-schedule=button"
        additionalClasses={`xs-mb2 ${disableButtonClass}`}
        context="schedule"
      />,
      <ScheduleButton
        key="listing-request-info-button"
        additionalClasses={`xs-mb5 ${disableButtonClass}`}
        context="requestInfo"
      />
    ];
  }
}
