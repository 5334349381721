import Basil from "basil.js";
const basil_options = {
  namespace: "oakwyn",
  storages: ["local", "cookie", "memory"],
  expireDays: 31
};

const basil = new Basil(basil_options);

export const LISTING_STORE_KEY = "oakwyn-listing-store";
const STORE_DEBUG = false;

const save = (key, value, options = {}) => {
  return new Promise((resolve, reject) => {
    basil.set(key, value, options);
    STORE_DEBUG &&
      console.log("setting local cookie/store ", key, " to ", value);
    resolve(value);
  });
};

const get = key => {
  return new Promise((resolve, reject) => {
    const value = basil.get(key);
    STORE_DEBUG &&
      console.log("getting local cookie/store ", key, " is value ", value);
    resolve(value);
  });
};

export const localCache = {
  save,
  get
};
