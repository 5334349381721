import React from "react";
import * as R from "ramda";

import { inject, observer } from "mobx-react";
import { ListingCard } from "./listing-card";
import { PulseLoader } from "react-spinners";

@inject("listingStore")
@observer
export class SearchResults extends React.Component {
  renderListingCards = () => {
    const { listings } = this.props.listingStore;

    return listings.map(listing => {
      return <ListingCard listing={listing} key={listing.id} />;
    });
  };

  render() {
    const { isLoading, showGridView } = this.props.listingStore;
    const showGridViewClass = showGridView ? "search-results--gallery" : "";

    return (
      <div className={`search-results ${showGridViewClass}`}>
        <div className="clearfix gutters search-results-gutters">
          {this.renderListingCards()}
        </div>
        {isLoading && (
          <div className="property-search-loading-wrapper xs-pt6 md-pt0">
            <PulseLoader color={"#b08c38"} loading={isLoading} />
          </div>
        )}
      </div>
    );
  }
}
