import React from "react";
import * as R from "ramda";
import { numberWithCommas } from "../../lib/utils";
import { inject, observer } from "mobx-react";
import MultiSelect from "@khanacademy/react-multi-select";

@inject("listingStore")
@observer
export class SearchFilters extends React.Component {
  handleMultiselectFieldChanged = (field, value) => {
    this.props.listingStore.replaceProp(field, value);
  };

  handleClearFiltersClicked = e => {
    this.props.listingStore.clearFilters();
  };

  bedSelectValueRenderer = (selected, options) => {
    if (selected && selected.length === 0) {
      return (
        <span>
          <i className="fa fa-bed xs-mr1" /> Bedrooms
        </span>
      );
    }

    if (selected && selected.length === options.length) {
      return "All bedrooms";
    }

    return (
      <span>
        <i className="fa fa-bed xs-mr1" /> {selected.join(", ")} Bed
      </span>
    );
  };

  filterSelectItemRenderer = (filterName, args) => {
    const { checked, option, onClick } = args;
    return (
      <span>
        <input
          type="checkbox"
          className="checkbox"
          onChange={onClick}
          checked={checked}
          tabIndex="-1"
          style={{ float: "right" }}
          id={`${option.label}-${filterName}-select`}
        />
        <label htmlFor={`${option.label}-${filterName}-select`}>
          {option.label}
        </label>
      </span>
    );
  };

  bedSelectItemRenderer = args => {
    const { checked, option, onClick } = args;
    return (
      <span>
        <input
          type="checkbox"
          className="checkbox"
          onChange={onClick}
          checked={checked}
          tabIndex="-1"
          style={{ float: "right" }}
          id={`${option.label}-bed-select`}
        />
        <label htmlFor={`${option.label}-bed-select`}>{option.label}</label>
      </span>
    );
  };

  bathSelectValueRenderer = (selected, options) => {
    if (selected && selected.length === 0) {
      return (
        <span>
          <i className="fa fa-bath xs-mr1" /> Bathrooms
        </span>
      );
    }

    if (selected && selected.length === options.length) {
      return "All bathrooms";
    }

    return (
      <span>
        <i className="fa fa-bath xs-mr1" /> {selected.join(", ")} Bath
      </span>
    );
  };

  bathSelectItemRenderer = args => {
    const { checked, option, onClick } = args;
    return (
      <span>
        <input
          type="checkbox"
          className="checkbox"
          onChange={onClick}
          checked={checked}
          tabIndex="-1"
          style={{ float: "right" }}
          id={`${option.label}-bath-select`}
        />
        <label htmlFor={`${option.label}-bath-select`}>{option.label}</label>
      </span>
    );
  };

  renderPriceOptions = (defaultLabel, options) => {
    return [
      <option key="default" value="">
        {defaultLabel}
      </option>,
      R.map(
        option => (
          <option key={option} value={option}>
            ${numberWithCommas(option)}
          </option>
        ),
        options
      )
    ];
  };

  typeSelectValueRenderer = (selected, options) => {
    if (selected) {
      if (selected.length === 0) {
        return <span>Type</span>;
      } else if (selected.length == 1) {
        return <span>{selected[0]}</span>;
      } else if (selected && selected.length === options.length) {
        return "All types";
      } else if (selected.length > 1) {
        return <span>{selected.length} Types</span>;
      }
    }
  };

  typeSelectItemRenderer = args => {
    const { checked, option, onClick } = args;
    return (
      <span>
        <input
          type="checkbox"
          className="checkbox"
          onChange={onClick}
          checked={checked}
          tabIndex="-1"
          style={{ float: "right" }}
          id={`${option.label}-type-select`}
        />
        <label htmlFor={`${option.label}-type-select`}>{option.label}</label>
      </span>
    );
  };

  renderClearButton = classNames => {
    return (
      <button
        className={classNames}
        onClick={e => this.handleClearFiltersClicked(e)}
      >
        Clear
      </button>
    );
  };

  render() {
    const {
      minPriceOptions,
      maxPriceOptions,
      bedOptions,
      bathOptions,
      propertyTypeOptions,
      bedroomsSelectionArray,
      bathsSelectionArray,
      orderByDirection,
      priceMax,
      priceMin,
      propertyTypesSelectionArray,
      setProp,
      showFilters
    } = this.props.listingStore;

    const showFiltersClass = showFilters ? "" : "xs-hide";
    const { handleFiltersToggleClicked } = this.props;

    return (
      <div
        className={`property-search-filter-container ${showFiltersClass} md-flex`}
      >
        <div className="property-search-filter-header">
          <div className="property-search-filter-header__item">
            <button
              className="button button--gray-transparent xs-px3 xs-py2"
              onClick={e => handleFiltersToggleClicked(e)}
            >
              <i className="fa fa-close" />
            </button>
          </div>
          <div className="property-search-filter-header__item property-search-filter-header__item--center">
            Filters
          </div>
          <div className="property-search-filter-header__item">
            {this.renderClearButton(
              "button button--gray-transparent xs-px3 xs-py2"
            )}
          </div>
        </div>
        <div className="property-search-filter-body">
          <div className="property-search-filter__label form-brand-label xs-mb1 md-mb0 md-mr2">
            Price
          </div>
          <div className="property-search-filter__price-wrapper">
            <select
              className="property-search-filter form-brand__item form-brand-select xs-mb2 md-mr2 md-mb0"
              onChange={e => setProp("priceMin", Number(e.target.value))}
              value={priceMin || ""}
            >
              {this.renderPriceOptions("Min", minPriceOptions)}
            </select>
            <select
              className="property-search-filter form-brand__item form-brand-select xs-mb3 md-mr2 md-mb0"
              onChange={e => setProp("priceMax", Number(e.target.value))}
              value={priceMax || ""}
            >
              {this.renderPriceOptions("Max", maxPriceOptions)}
            </select>
          </div>

          <div className="property-search-filter__label form-brand-label xs-mb1 md-hide">
            Beds
          </div>
          <div className="xs-mb3 md-mb0 md-mr2">
            <MultiSelect
              options={bedOptions}
              onSelectedChanged={selected =>
                this.handleMultiselectFieldChanged(
                  "bedroomsSelectionArray",
                  selected
                )
              }
              selected={bedroomsSelectionArray}
              hasSelectAll={false}
              disableSearch={true}
              valueRenderer={this.bedSelectValueRenderer}
              ItemRenderer={this.bedSelectItemRenderer}
            />
          </div>

          <div className="property-search-filter__label form-brand-label xs-mb1 md-hide">
            Baths
          </div>
          <div className="xs-mb3 md-mb0 md-mr2">
            <MultiSelect
              options={bathOptions}
              onSelectedChanged={selected =>
                this.handleMultiselectFieldChanged(
                  "bathsSelectionArray",
                  selected
                )
              }
              selected={bathsSelectionArray}
              hasSelectAll={false}
              disableSearch={true}
              valueRenderer={this.bathSelectValueRenderer}
              ItemRenderer={this.bathSelectItemRenderer}
            />
          </div>

          <div className="property-search-filter__label form-brand-label xs-mb2 md-mb0 md-mr1">
            Type
          </div>
          <div className="xs-mb3 md-mb0 md-mr-auto">
            <MultiSelect
              options={propertyTypeOptions}
              onSelectedChanged={selected =>
                this.handleMultiselectFieldChanged(
                  "propertyTypesSelectionArray",
                  selected
                )
              }
              selected={propertyTypesSelectionArray}
              hasSelectAll={false}
              disableSearch={true}
              valueRenderer={this.typeSelectValueRenderer}
              ItemRenderer={this.typeSelectItemRenderer}
            />
          </div>

          {this.renderClearButton(
            "button button--secondary button--small button--gray xs-hide md-inline-block"
          )}

          <div className="md-hide">
            <div className="property-search-filter__label form-brand-label xs-mb2 md-mb0 md-mr1">
              Sort Results by
            </div>
            <select
              className="property-search-filter form-brand__item form-brand-select xs-mb2 md-mr2 md-mb0"
              onChange={e => setProp("orderByDirection", e.target.value)}
              value={orderByDirection || "asc"}
            >
              <option value="asc">Price (low - high)</option>
              <option value="desc">Price (high - low)</option>
            </select>
          </div>

          <div className="property-search-filter-submit-container">
            <button
              className="button button--small button--brand xs-col-12"
              onClick={e => handleFiltersToggleClicked(e)}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    );
  }
}
